import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { fetch_card_transactions } from './Util_API_calls';

const Modal_CardTransactions = ({ isOpen, onClose, customer_id, card_id }) => {
  const [transactionsData, setTransactionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      setErrorMessage('');
      
      console.log("Fetching card transactions with:", { customer_id, card_id });

      if (!customer_id || !card_id) {
        console.error("Missing customer_id or card_id", { customer_id, card_id });
        setErrorMessage('Dati mancanti per recuperare le transazioni');
        setLoading(false);
        return;
      }

      try {
        const result = await fetch_card_transactions(customer_id, card_id);
        console.log("API response:", result);
        if (result.status === 'success') {
          setTransactionsData(result.card_transactions);
        } else {
          setErrorMessage(result.message || 'Non ci sono dettagli al momento');
        }
      } catch (error) {
        console.error("Error fetching card transactions:", error);
        setErrorMessage('Errore nel recupero delle transazioni');
      }
      setLoading(false);
    };

    if (isOpen) {
      fetchDetails();
    }
  }, [isOpen, customer_id, card_id]);

  const handleClose = () => {
    setTransactionsData([]);
    setErrorMessage('');
    onClose();
  };

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedTransactions = [...transactionsData].sort((a, b) => {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null) return -1;

    if (orderBy === 'amount') {
      return orderDirection === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    }

    if (orderBy === 'created_at') {
      return orderDirection === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
    }

    return orderDirection === 'asc'
      ? a[orderBy].toString().localeCompare(b[orderBy].toString())
      : b[orderBy].toString().localeCompare(a[orderBy].toString());
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle>Transazioni Carta</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : sortedTransactions.length === 0 ? (
          <Typography>Non ci sono transazioni al momento</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {['created_at', 'card_masked_pan', 'amount', 'currency', 'description', 'transaction_type', 'merchant_name', 'merchant_country', 'merchant_id'].map((column) => (
                  <TableCell key={column}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? orderDirection : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column.replace('_', ' ')}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(transaction.created_at).toLocaleString()}</TableCell>
                  <TableCell>{transaction.card_masked_pan}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.currency}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell>{transaction.transaction_type}</TableCell>
                  <TableCell>{transaction.merchant_name || 'N/A'}</TableCell>
                  <TableCell>{transaction.merchant_country || 'N/A'}</TableCell>
                  <TableCell>{transaction.merchant_id || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal_CardTransactions;