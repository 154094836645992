// Modal_KycUpdate.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Import the API function
import { updateBusinessRiskScore } from './Util_API_calls';

const Modal_KycUpdate = ({ isOpen, onClose, assessmentData, business }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Mapping of assessmentData keys to labels
  const labels = {
    // General Information
    general_information_how_client_get_to_know_paynovate: 'How did the client get to know about Paynovate?',
    general_information_how_was_the_client_met: 'How was the client met?',
    general_information_what_is_the_client_sector_category: 'What is the client sector category according to our merchant acceptance policy?',
    general_information_is_there_a_clear_view_of_the_client_business_activity: 'Is there a clear view of the client\'s business activity?',
    general_information_does_business_activity_make_sense_for_the_account: 'Does this business activity make sense with the purpose of the account requested?',
    general_information_where_is_the_client_incorporated: 'Where is the client incorporated?',
    general_information_incorporation_country_name: 'Incorporation country name',
    general_information_sanctions: 'Sanctions',

    // Source of Financing
    source_of_financing_adverse_media: 'Adverse media',
    source_of_financing_has_client_provided_audited_statements: 'Has the client provided management accounts or audited financial statements?',
    source_of_financing_does_sow_sof_make_sense: 'Does the stated SoW/SoF make sense with what you know about the client?',
    source_of_financing_is_sow_sof_identifiable: 'Is the SoW/SoF clearly identifiable?',
    source_of_financing_funds_from_reputable_institution: 'Are the funds coming from a recognised reputable financial/credit institution?',
    source_of_financing_funds_from_personal_accounts: 'Are the funds coming from personal accounts?',
    source_of_financing_funds_from_third_parties: 'Are any of the funds coming from unrelated third parties?',

    // Verification
    verification_has_client_provided_proof_of_identification: 'Has the client provided acceptable proof of identification and proof of address for all the involved parties?',
    verification_have_you_confirmed_authenticity_of_certifier: 'Have you been able to confirm the authenticity/professional status of the certifier?',
    verification_was_client_hesitant_in_providing_documents: 'Was the client hesitant to provide the requested documentation?',
    verification_sanctions_screening_provided_positive_hit: 'Has the sanctions screening tool provided any related positive hit?',

    // UBO
    ubo_pep: 'UBO - PEP',
    ubo_sanctions: 'UBO - Sanctions',
    ubo_adverse_media: 'UBO - Adverse media',
    ubo_residing_in_sanctioned_country: 'UBO - Residing in a sanctioned country',
    ubo_residing_in_high_risk_country: 'UBO - Residing in a high-risk country',

    // Directors
    directors_pep: 'Directors - PEP',
    directors_sanctions: 'Directors - Sanctions',
    directors_adverse_media: 'Directors - Adverse media',
    directors_residing_in_sanctioned_country: 'Directors - Residing in a sanctioned country',
    directors_residing_in_high_risk_country: 'Directors - Residing in a high-risk country',

    // Transactions
    transactions_did_client_provide_expected_monthly_inwards_volume: 'Did the client provide expected monthly inwards volume?',
    transactions_expected_annual_incoming_volume: 'Expected annual incoming volume',
    transactions_customer_provided_expected_monthly_outwards_volume: 'Did the client provide expected monthly outwards volume?',
    transactions_expected_annual_outgoing_volume: 'Expected annual outgoing volume',
    transactions_customer_provided_5_counterparties_inwards: 'Did the client provide 5 counterparties inwards?',
    transactions_customer_provided_5_counterparties_outwards: 'Did the client provide 5 counterparties outwards?',
    transactions_is_client_involved_in_cash_dominant_turnover: 'Is the client involved in cash-dominant turnover?',
    transactions_is_transaction_risk_of_money_laundering: 'Are transactions at risk of money laundering?',
  };

  // This function opens the confirmation dialog
  const handleSubmit = () => {
    setConfirmationOpen(true);
  };

  // This function handles the API call and result processing
  const handleConfirmSubmit = async () => {
    setConfirmationOpen(false);
    setIsSubmitting(true);
    try {
      // Prepare all parameters
      const params = {
        business_id: business.business_id,
        ...assessmentData,
      };

      // Log the business.id and parameters being sent
      console.log('business.id:', business.id);
      console.log('Parameters being sent to API:', params);

      // Call the API function with all parameters individually
      const result = await updateBusinessRiskScore(
        params.business_id,
        params.general_information_how_client_get_to_know_paynovate,
        params.general_information_how_was_the_client_met,
        params.general_information_what_is_the_client_sector_category,
        params.general_information_is_there_a_clear_view_of_the_client_business_activity,
        params.general_information_does_business_activity_make_sense_for_the_account,
        params.general_information_where_is_the_client_incorporated,
        params.general_information_incorporation_country_name,
        params.general_information_sanctions,
        params.source_of_financing_adverse_media,
        params.source_of_financing_has_client_provided_audited_statements,
        params.source_of_financing_does_sow_sof_make_sense,
        params.source_of_financing_is_sow_sof_identifiable,
        params.source_of_financing_funds_from_reputable_institution,
        params.source_of_financing_funds_from_personal_accounts,
        params.source_of_financing_funds_from_third_parties,
        params.verification_has_client_provided_proof_of_identification,
        params.verification_have_you_confirmed_authenticity_of_certifier,
        params.verification_was_client_hesitant_in_providing_documents,
        params.verification_sanctions_screening_provided_positive_hit,
        params.ubo_pep,
        params.ubo_sanctions,
        params.ubo_adverse_media,
        params.ubo_residing_in_sanctioned_country,
        params.ubo_residing_in_high_risk_country,
        params.directors_pep,
        params.directors_sanctions,
        params.directors_adverse_media,
        params.directors_residing_in_sanctioned_country,
        params.directors_residing_in_high_risk_country,
        params.transactions_did_client_provide_expected_monthly_inwards_volume,
        params.transactions_expected_annual_incoming_volume,
        params.transactions_customer_provided_expected_monthly_outwards_volume,
        params.transactions_expected_annual_outgoing_volume,
        params.transactions_customer_provided_5_counterparties_inwards,
        params.transactions_customer_provided_5_counterparties_outwards,
        params.transactions_is_client_involved_in_cash_dominant_turnover,
        params.transactions_is_transaction_risk_of_money_laundering
      );

      // Handle the API response
      if (result.status === 'success') {
        if (result.outcome === 'SAVED') {
          setMessage('Dati salvati, mancano dati per sottomettere l\'applicazione');
        } else if (result.outcome === 'SUBMITTED') {
          setMessage('Applicazione Sottomessa');
        } else if (result.outcome === 'ABORTED') {
          setMessage('Errore, la compagnia è già stata sottomessa.');
        } else {
          setMessage('Errore, contattare l\'amministratore.');
        }
      } else {
        setMessage('Errore, contattare l\'amministratore.');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setMessage('Errore, contattare l\'amministratore.');
    }
    setIsSubmitting(false);
    setMessageDialogOpen(true);
  };

  // Handle closing the message dialog
  const handleCloseMessageDialog = () => {
    setMessageDialogOpen(false);
    onClose(); // Close the modal
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} maxWidth="lg">
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h5"><strong>Controllo dati KYB</strong></Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '20px' }}>
          Assicurati che tutti i dati sono corretti prima di procedere.
        </Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            {Object.entries(assessmentData).map(([key, value]) => (
              <React.Fragment key={key}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    {labels[key] || key}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
        <Button onClick={onClose} size="large" sx={{ fontSize: '1.1rem', padding: '10px 20px' }}>Chiudi</Button>
        {!isSubmitting && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            size="large"
            sx={{ fontSize: '1.1rem', padding: '10px 20px' }}
          >
            Salva/Invia
          </Button>
        )}
      </DialogActions>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <DialogTitle>Sei sicuro?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>No</Button>
          <Button onClick={handleConfirmSubmit} color="primary">
            Sì
          </Button>
        </DialogActions>
      </Dialog>

      {/* Message Dialog */}
      <Dialog open={messageDialogOpen} onClose={handleCloseMessageDialog}>
        <DialogTitle>Risultato</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default Modal_KycUpdate;
