// Page_Kycstatus.js
import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  ThemeProvider,
  TableSortLabel,
  Typography,
  Paper,
} from '@mui/material';
import { get_business_customer_list } from './Util_API_calls';
import Modal_KybOptions from './Modal_KybOptions';
import Modal_kybAssessment from './Modal_kybAssessment';

function Page_Kycstatus({ onDataChange, ...props }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [businessCustomers, setBusinessCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isKybOptionsModalOpen, setIsKybOptionsModalOpen] = useState(false);
  const [isKybAssessmentModalOpen, setIsKybAssessmentModalOpen] = useState(false);
  const [apiResponseJson, setApiResponseJson] = useState(null);
  const [isKybAssessmentBModalOpen, setIsKybAssessmentBModalOpen] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // Function to format dates
  const formatDate = (dateString) => {
    if (!dateString || dateString === 'N/A') return 'N/A';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowClick = (business) => {
    setSelectedBusiness(business);
    setIsKybOptionsModalOpen(true);
  };

  const filteredData = businessCustomers.filter((business) => {
    const matchesSearch =
      (business.name && business.name.toLowerCase().includes(searchTerm)) ||
      (business.business_id && business.business_id.toLowerCase().includes(searchTerm)) ||
      (business.phone_number && business.phone_number.toLowerCase().includes(searchTerm)) ||
      (String(business.country_id).toLowerCase().includes(searchTerm));
    return matchesSearch;
  });

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === 'name') {
      return order === 'asc'
        ? (a.name || '').localeCompare(b.name || '')
        : (b.name || '').localeCompare(a.name || '');
    }
    if (orderBy === 'business_id') {
      return order === 'asc'
        ? (a.business_id || '').localeCompare(b.business_id || '')
        : (b.business_id || '').localeCompare(a.business_id || '');
    }
    if (orderBy === 'phone_number') {
      return order === 'asc'
        ? (a.phone_number || '').localeCompare(b.phone_number || '')
        : (b.phone_number || '').localeCompare(a.phone_number || '');
    }
    if (orderBy === 'country_id') {
      return order === 'asc'
        ? String(a.country_id).localeCompare(String(b.country_id))
        : String(b.country_id).localeCompare(String(a.country_id));
    }
    if (orderBy === 'created_at') {
      return order === 'asc'
        ? new Date(a.created_at) - new Date(b.created_at)
        : new Date(b.created_at) - new Date(a.created_at);
    }
    if (orderBy === 'updated_at') {
      return order === 'asc'
        ? new Date(a.updated_at) - new Date(b.updated_at)
        : new Date(b.updated_at) - new Date(a.updated_at);
    }
    if (orderBy === 'progression') {
      return order === 'asc'
        ? a.progression - b.progression
        : b.progression - a.progression;
    }
    return 0;
  });

  useEffect(() => {
    const fetchBusinessCustomers = async () => {
      setLoading(true);
      const response = await get_business_customer_list();

      // Save the raw JSON response to state
      setApiResponseJson(JSON.stringify(response, null, 2));

      if (response && response.status === 'success') {
        const businesses = response.businesses.map((business) => {
          const answeredQuestions = Object.values(business.saved_data || {}).filter(value => value !== null).length;
          const totalQuestions = 37; // Total number of questions
          const progressionPercentage = Math.round((answeredQuestions / totalQuestions) * 100);
          return {
            name: business.name || 'N/A',
            business_id: business.business_id || 'N/A',
            phone_number: business.phone_number || 'N/A',
            country_id: business.country_id !== null ? business.country_id : 'N/A',
            created_at: business.created_at || 'N/A',
            updated_at: business.updated_at || 'N/A',
            saved_data: business.saved_data || {},
            progression: progressionPercentage,
          };
        });
        setBusinessCustomers(businesses);
      } else {
        console.error('Failed to fetch business customers:', response.message);
      }
      setLoading(false);
    };
    fetchBusinessCustomers();
  }, []);

  const handleKybOptionsClose = () => {
    setIsKybOptionsModalOpen(false);
    setSelectedBusiness(null);
  };

  const handleOptionSelect = (option) => {
    setIsKybOptionsModalOpen(false);

    switch (option) {
      case 'kyb_assessment':
        setIsKybAssessmentModalOpen(true);
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Grid item xs={12}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Display the raw JSON response in a collapsible section */}
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '20px' }}>
          <Typography variant="h6">API Response JSON:</Typography>
          <TextField
            value={apiResponseJson || ''}
            multiline
            fullWidth
            rows={10}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Paper>

        <Table style={{ marginTop: '20px' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'name')}
                >
                  Name Company
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'business_id'}
                  direction={orderBy === 'business_id' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'business_id')}
                >
                  Business ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'phone_number'}
                  direction={orderBy === 'phone_number' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'phone_number')}
                >
                  Phone
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'country_id'}
                  direction={orderBy === 'country_id' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'country_id')}
                >
                  Country ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'created_at')}
                >
                  Data Creazione
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'updated_at'}
                  direction={orderBy === 'updated_at' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'updated_at')}
                >
                  Data Modifica
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'progression'}
                  direction={orderBy === 'progression' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'progression')}
                >
                  Progression
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((business, index) => (
              <TableRow key={index} onClick={() => handleRowClick(business)} style={{ cursor: 'pointer' }}>
                <TableCell>{business.name}</TableCell>
                <TableCell>{business.business_id}</TableCell>
                <TableCell>{business.phone_number}</TableCell>
                <TableCell>{business.country_id}</TableCell>
                <TableCell>{formatDate(business.created_at)}</TableCell>
                <TableCell>{formatDate(business.updated_at)}</TableCell>
                <TableCell>
                  <Typography style={{ color: 'blue', fontSize: 'larger' }}>
                    {business.progression}%
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={sortedData.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
        {selectedBusiness && (
          <Modal_KybOptions
            isOpen={isKybOptionsModalOpen}
            onClose={handleKybOptionsClose}
            business={selectedBusiness}
            onOptionSelect={handleOptionSelect}
          />
        )}
        {selectedBusiness && isKybAssessmentModalOpen && (
          <Modal_kybAssessment
            isOpen={isKybAssessmentModalOpen}
            onClose={() => setIsKybAssessmentModalOpen(false)}
            business={selectedBusiness}
          />
        )}
      </ThemeProvider>
    </div>
  );
}

export default Page_Kycstatus;
