import React from 'react';
import { Dialog, DialogTitle, Button, Box, Grid } from '@mui/material';

const Modal_PersonalBusiness = ({ isOpen, onClose, user, onSelectAccountType }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Box sx={{ bgcolor: '#2196f3', color: 'white', textAlign: 'center', p: 2 }}>
        <DialogTitle>Account Types</DialogTitle>
      </Box>
      <Grid container spacing={2} direction="column" sx={{ p: 3 }}>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => onSelectAccountType('personal')}
            sx={{ height: 56 }}  // Adjust height for bigger button
          >
            Conti Personali
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => onSelectAccountType('business')}
            disabled
            sx={{ height: 56 }}  // Adjust height for bigger button
          >
            Conti Business
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={onClose}
            sx={{ height: 56 }}  // Adjust height for bigger button
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal_PersonalBusiness;
