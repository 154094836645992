// Modal_KybOptions.js
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Grid, Typography, Box } from '@mui/material';

const Modal_KybOptions = ({ isOpen, onClose, business, onOptionSelect }) => {
  const options = [{ label: 'KYB Assessment', value: 'kyb_assessment' }];

  const handleOptionClick = (option) => {
    onOptionSelect(option);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      <Box sx={{ bgcolor: '#2196f3', color: 'white', textAlign: 'center' }}>
        <DialogTitle>KYB Options</DialogTitle>
      </Box>
      <DialogContent>
        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="h6" component="div" gutterBottom>
            Company: {business.company_name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Business ID: {business.business_id}
          </Typography>
        </Box>
        <Grid container spacing={2} direction="column">
          {options.map((option) => (
            <Grid item xs={12} key={option.value}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={() => handleOptionClick(option.value)}
                sx={{ height: 56 }}
              >
                {option.label}
              </Button>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={onClose}
              sx={{ height: 56 }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal_KybOptions;
