// Modal_kybAssessment.js
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button,TextField, Box, Select, MenuItem, InputLabel, FormControl,Typography, Grid, Autocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal_KycUpdate from './Modal_KycUpdate'; // Adjust the path as necessary



const Modal_kybAssessment = ({ isOpen, onClose, business, onOpenNextModal }) => {
  const [isKycUpdateOpen, setIsKycUpdateOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});

  // Initialize state variables with saved data
  const savedData = business.saved_data || {};

  const [generalInfo, setGeneralInfo] = useState({
    how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
    how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
    what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
    is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
    does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
    where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
    incorporation_country_name: savedData.general_information_incorporation_country_name || '',
    sanctions: savedData.general_information_sanctions || '',
  });

  const [sourceOfFinancing, setSourceOfFinancing] = useState({
    adverse_media: savedData.source_of_financing_adverse_media || '',
    has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
    does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
    is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
    funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
    funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
    funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
  });

  const [verification, setVerification] = useState({
    has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
    have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
    was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
    sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
  });

  const [ubo, setUbo] = useState({
    pep: savedData.ubo_pep || '',
    sanctions: savedData.ubo_sanctions || '',
    adverse_media: savedData.ubo_adverse_media || '',
    residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
  });

  const [directors, setDirectors] = useState({
    pep: savedData.directors_pep || '',
    sanctions: savedData.directors_sanctions || '',
    adverse_media: savedData.directors_adverse_media || '',
    residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
  });

  const [transactions, setTransactions] = useState({
    did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
    expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
    did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
    expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
    did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
    did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
    is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
    is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
  });

// Update state when business prop changes
React.useEffect(() => {
  const savedData = business.saved_data || {};

  setGeneralInfo({
    how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
    how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
    what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
    is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
    does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
    where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
    incorporation_country_name: savedData.general_information_incorporation_country_name || '',
    sanctions: savedData.general_information_sanctions || '',
  });

  setSourceOfFinancing({
    adverse_media: savedData.source_of_financing_adverse_media || '',
    has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
    does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
    is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
    funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
    funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
    funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
  });

  setVerification({
    has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
    have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
    was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
    sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
  });

  setUbo({
    pep: savedData.ubo_pep || '',
    sanctions: savedData.ubo_sanctions || '',
    adverse_media: savedData.ubo_adverse_media || '',
    residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
  });

  setDirectors({
    pep: savedData.directors_pep || '',
    sanctions: savedData.directors_sanctions || '',
    adverse_media: savedData.directors_adverse_media || '',
    residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
  });

  setTransactions({
    did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
    expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
    did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
    expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
    did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
    did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
    is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
    is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
  });
}, [business]);

  // Handle form submission
  const handleSubmit = () => {
    const assessmentData = {
      general_information_how_client_get_to_know_paynovate: generalInfo.how_client_get_to_know_paynovate,
      general_information_how_was_the_client_met: generalInfo.how_was_the_client_met,
      general_information_what_is_the_client_sector_category: generalInfo.what_is_the_client_sector_category,
      general_information_is_there_a_clear_view_of_the_client_business_activity: generalInfo.is_there_a_clear_view_of_the_client_business_activity,
      general_information_does_business_activity_make_sense_for_the_account: generalInfo.does_business_activity_make_sense_for_the_account,
      general_information_where_is_the_client_incorporated: generalInfo.where_is_the_client_incorporated,
      general_information_incorporation_country_name: generalInfo.incorporation_country_name,
      general_information_sanctions: generalInfo.sanctions,
  
      source_of_financing_adverse_media: sourceOfFinancing.adverse_media,
      source_of_financing_has_client_provided_audited_statements: sourceOfFinancing.has_client_provided_audited_statements,
      source_of_financing_does_sow_sof_make_sense: sourceOfFinancing.does_sow_sof_make_sense,
      source_of_financing_is_sow_sof_identifiable: sourceOfFinancing.is_sow_sof_identifiable,
      source_of_financing_funds_from_reputable_institution: sourceOfFinancing.funds_from_reputable_institution,
      source_of_financing_funds_from_personal_accounts: sourceOfFinancing.funds_from_personal_accounts,
      source_of_financing_funds_from_third_parties: sourceOfFinancing.funds_from_third_parties,
  
      verification_has_client_provided_proof_of_identification: verification.has_client_provided_proof_of_identification,
      verification_have_you_confirmed_authenticity_of_certifier: verification.have_you_confirmed_authenticity_of_certifier,
      verification_was_client_hesitant_in_providing_documents: verification.was_client_hesitant_in_providing_documents,
      verification_sanctions_screening_provided_positive_hit: verification.sanctions_screening_provided_positive_hit,
  
      ubo_pep: ubo.pep,
      ubo_sanctions: ubo.sanctions,
      ubo_adverse_media: ubo.adverse_media,
      ubo_residing_in_sanctioned_country: ubo.residing_in_sanctioned_country,
      ubo_residing_in_high_risk_country: ubo.residing_in_high_risk_country,
  
      directors_pep: directors.pep,
      directors_sanctions: directors.sanctions,
      directors_adverse_media: directors.adverse_media,
      directors_residing_in_sanctioned_country: directors.residing_in_sanctioned_country,
      directors_residing_in_high_risk_country: directors.residing_in_high_risk_country,
  
      transactions_did_client_provide_expected_monthly_inwards_volume: transactions.did_client_provide_expected_monthly_inwards_volume,
      transactions_expected_annual_incoming_volume: transactions.expected_annual_incoming_volume,
      transactions_customer_provided_expected_monthly_outwards_volume: transactions.did_client_provide_expected_monthly_outwards_volume,
      transactions_expected_annual_outgoing_volume: transactions.expected_annual_outgoing_volume,
      transactions_customer_provided_5_counterparties_inwards: transactions.did_client_provide_5_counterparties_inwards,
      transactions_customer_provided_5_counterparties_outwards: transactions.did_client_provide_5_counterparties_outwards,
      transactions_is_client_involved_in_cash_dominant_turnover: transactions.is_client_involved_in_cash_dominant_turnover,
      transactions_is_transaction_risk_of_money_laundering: transactions.is_transaction_risk_of_money_laundering,
    };
  
    setAssessmentData(assessmentData);
  
    console.log('Assessment Data:', assessmentData);
  
    if (onOpenNextModal) {
      onOpenNextModal(assessmentData);
    }
  
    setIsKycUpdateOpen(true);
  };


const handleCloseBothModals = () => {
  setIsKycUpdateOpen(false); // Close the second modal
  onClose(); // Close the first modal
};


// Update state when business prop changes
React.useEffect(() => {
  const savedData = business.saved_data || {};

  setGeneralInfo({
    how_client_get_to_know_paynovate: savedData.general_information_how_client_get_to_know_paynovate || '',
    how_was_the_client_met: savedData.general_information_how_was_the_client_met || '',
    what_is_the_client_sector_category: savedData.general_information_what_is_the_client_sector_category || '',
    is_there_a_clear_view_of_the_client_business_activity: savedData.general_information_is_there_a_clear_view_of_the_client_business_activity || '',
    does_business_activity_make_sense_for_the_account: savedData.general_information_does_business_activity_make_sense_for_the_account || '',
    where_is_the_client_incorporated: savedData.general_information_where_is_the_client_incorporated || '',
    incorporation_country_name: savedData.general_information_incorporation_country_name || '',
    sanctions: savedData.general_information_sanctions || '',
  });

  setSourceOfFinancing({
    adverse_media: savedData.source_of_financing_adverse_media || '',
    has_client_provided_audited_statements: savedData.source_of_financing_has_client_provided_audited_statements || '',
    does_sow_sof_make_sense: savedData.source_of_financing_does_sow_sof_make_sense || '',
    is_sow_sof_identifiable: savedData.source_of_financing_is_sow_sof_identifiable || '',
    funds_from_reputable_institution: savedData.source_of_financing_funds_from_reputable_institution || '',
    funds_from_personal_accounts: savedData.source_of_financing_funds_from_personal_accounts || '',
    funds_from_third_parties: savedData.source_of_financing_funds_from_third_parties || '',
  });

  setVerification({
    has_client_provided_proof_of_identification: savedData.verification_has_client_provided_proof_of_identification || '',
    have_you_confirmed_authenticity_of_certifier: savedData.verification_have_you_confirmed_authenticity_of_certifier || '',
    was_client_hesitant_in_providing_documents: savedData.verification_was_client_hesitant_in_providing_documents || '',
    sanctions_screening_provided_positive_hit: savedData.verification_sanctions_screening_provided_positive_hit || '',
  });

  setUbo({
    pep: savedData.ubo_pep || '',
    sanctions: savedData.ubo_sanctions || '',
    adverse_media: savedData.ubo_adverse_media || '',
    residing_in_sanctioned_country: savedData.ubo_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.ubo_residing_in_high_risk_country || '',
  });

  setDirectors({
    pep: savedData.directors_pep || '',
    sanctions: savedData.directors_sanctions || '',
    adverse_media: savedData.directors_adverse_media || '',
    residing_in_sanctioned_country: savedData.directors_residing_in_sanctioned_country || '',
    residing_in_high_risk_country: savedData.directors_residing_in_high_risk_country || '',
  });

  setTransactions({
    did_client_provide_expected_monthly_inwards_volume: savedData.transactions_did_client_provide_expected_monthly_inwards_volume || '',
    expected_annual_incoming_volume: savedData.transactions_expected_annual_incoming_volume || '',
    did_client_provide_expected_monthly_outwards_volume: savedData.transactions_customer_provided_expected_monthly_outwards_volume || '',
    expected_annual_outgoing_volume: savedData.transactions_expected_annual_outgoing_volume || '',
    did_client_provide_5_counterparties_inwards: savedData.transactions_customer_provided_5_counterparties_inwards || '',
    did_client_provide_5_counterparties_outwards: savedData.transactions_customer_provided_5_counterparties_outwards || '',
    is_client_involved_in_cash_dominant_turnover: savedData.transactions_is_client_involved_in_cash_dominant_turnover || '',
    is_transaction_risk_of_money_laundering: savedData.transactions_is_transaction_risk_of_money_laundering || '',
  });
}, [business]);


// Options for the dropdowns
const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const howClientGetToKnowOptions = [
  { label: 'Existing Group', value: 'existing_group' },
  { label: 'Internet Search', value: 'internet_search' },
  { label: 'Social Media', value: 'social_media' },
  { label: 'Known to Company', value: 'known_to_company' },
  { label: 'Sales Team', value: 'sales_team' },
];

const howWasClientMetOptions = [
  { label: 'Face to Face', value: 'face_to_face' },
  { label: 'Remotely', value: 'remotely' },
];

// Options for client sector category
const clientSectorCategoryOptions = [
  { label: 'Alcohol Sales', value: 'alcohol_sales' },
  { label: 'Drugs and Tools Specifically Intended for the Production of Drugs, Drug Paraphernalia, Illegal Drugs, Substances Designed to Mimic Illegal Drugs and/or Other Psychoactive Products', value: 'drugs_and_tools_specifically_intended_for_the_production_of_drugs_drug_paraphernalia_illegal_drugs_substances_designed_to_mimic_illegal_drugs_and_or_other_psychoactive_products' },
  { label: 'Illegal Products or Services or Any Service Providing Peripheral Support of Illegal Activities Including Illegal Gambling Services', value: 'illegal_products_or_services_or_any_service_providing_peripheral_support_of_illegal_activities_including_illegal_gambling_services' },
  { label: 'Legal Gambling or Game of Skill Where Participants Receive Cash or Cash Equivalents Prizes with Material Value Including but Not Limited to Casinos, Poker, Bingo, Slot Machines, Betting, Lotteries, Racing, Fantasy Sports', value: 'legal_gambling_or_game_of_skill_where_participants_receive_cash_or_cash_equivalents_orizes_with_of_material_value_including_but_not_limited_to_casinos_poker_bingo_slot_machines_betting_lotteries_racing_fantasy_sports' },
  { label: 'Products or Services Specifically Offered or Intended to be Used to Create, Produce, or Grow Drugs or Drug Ingredients', value: 'products_or_services_specifically_offered_or_intended_to_be_used_to_create_produce_or_grow_drugs_or_drug_ingredients' },
  { label: 'Pharmaceuticals Prescription Medicine and Medical Devices Including Animal Pharmaceuticals', value: 'pharmaceuticals_prescription_medicine_and_medical_devices_including_animal_pharmaceuticals' },
  { label: 'Tobacco Smoking Supplies E-Cigarettes E-Liquids Vaping Liquid Vaping Accessories', value: 'tobacco_smoking_supplies_e_cigarettes_e_liquids_vaping_liquid_vaping_accessories' },
  { label: 'Trade of Fireworks Flammable Radioactive Materials', value: 'trade_of_fireworks_flammable_oadioactive_materials' },
  { label: 'Trade of Weapons Ammunitions Military Arms Explosive Devices and Firearm Parts', value: 'trade_of_weapons_ammunitions_military_arms_explosive_devices_and_firearm_parts' },
  { label: 'Any Type of US Based Gambling Services', value: 'any_type_of_us_based_gambling_services' },
  { label: 'Cryptocurrency Exchange', value: 'cryptocurrency_exchange' },
  { label: 'High Risk Securities Such as Contract for Difference (CFD), Financial Spread Betting, Initial Coin Offering (ICO), Forex, Currency Options, Cryptocurrency Options Trading and Purchases', value: 'high_risk_securities_such_as_contract_for_difference_cfd_financial_spread_betting_initial_coin_offering_ico_forex_currency_options_cryptocurrency_options_trading_and_purchases' },
  { label: 'Security Brokers Investments of Any Kind Including the Purchase of Securities, Currencies, Derivatives, Commodities, Shares, Options, Precious Metals, Other Financial Instruments', value: 'security_brokers_investments_of_any_kind_including_the_purchase_of_securities_currencies_derivatives_commodities_shares_options_previous_metals_other_financial_instruments' },
  { label: 'Decryption and Descrambler Products and Services Devices Designed to Block Jam or Interfere with Cellular and Personal Communications', value: 'decryption_and_descrambleroducts_and_services_devices_designed_to_block_jam_or_interfere_with_cellular_and_personal_communications' },
  { label: 'Specific Cosmetic Products that Claims Extreme Result Anti-Wrinkle Skin Repair etc.', value: 'specific_cosmetic_products_that_claims_extreme_result_anti_wrinkle_skin_repair_etc_' },
  { label: 'Provision of IT Services', value: 'provision_of_it_services' },
  { label: 'Acquisition or Business Investment Services', value: 'acquisition_or_business_investment_services' },
  { label: 'Regulated Payment Services', value: 'regulated_payment_services' },
  { label: 'Unregulated Payment Services', value: 'unregulated_payment_services' },
  { label: 'Consultancy Services', value: 'consultancy_services' },
  { label: 'Holding Entity for Onboarded Trading Entity', value: 'holding_entity_for_onboarded_trading_entity' },
  { label: 'Holding Entity without an Onboarded Trading Entity', value: 'holding_entity_without_an_onboarded_trading_entity' },
  { label: 'Gambling Casino Sites for Operational Day to Day Transactions and PSP Settlements', value: 'gambling_casino_sites_for_operational_day_to_day_transactions_and_psp_settlements' },
  { label: 'Gambling Casino Sites for Direct Player Payments Deposits and Withdrawals', value: 'gambling_casino_sites_for_direct_player_payments_deposits_and_withdrawals' },
  { label: 'Wholesale of Flowers and Plants', value: 'wholesale_of_flowers_and_plants' },
  { label: 'Wholesale of Fruit and Vegetables', value: 'wholesale_of_fruit_and_vegetables' },
  { label: 'Wholesale of Meat and Meat Products', value: 'wholesale_of_meat_and_meat_products' },
  { label: 'Wholesale of Dairy Products Eggs and Edible Oils and Fats', value: 'wholesale_of_dairy_products_eggs_and_edible_oils_and_fats' },
  { label: 'Wholesale of Fruit and Vegetable Juices Mineral Water and Soft Drinks', value: 'wholesale_of_fruit_and_vegetable_juices_mineral_water_and_soft_drinks' },
  { label: 'Wholesale of Sugar and Chocolate and Sugar Confectionery', value: 'wholesale_of_sugar_and_chocolate_and_sugar_confectionery' },
  { label: 'Wholesale of Coffee Tea Cocoa and Spices', value: 'wholesale_of_coffee_tea_cocoa_and_spices' },
  { label: 'Wholesale of Other Food Including Fish Crustaceans and Molluscs', value: 'wholesale_of_other_food_including_fish_crustaceans_and_molluscs' },
  { label: 'Wholesale of Textiles', value: 'wholesale_of_textiles' },
  { label: 'Wholesale of Clothing and Footwear', value: 'wholesale_of_clothing_and_footwear' },
  { label: 'Wholesale of Audio Tapes Records CDs and Video Tapes and the Equipment on which These are Played', value: 'wholesale_of_audio_tapes_records_cds_and_video_tapes_and_the_equipment_on_which_these_are_played' },
  { label: 'Wholesale of Radio Television Goods Electrical Household Appliances Other than Records Tapes CDs Video Tapes and the Equipment Used for Playing Them', value: 'wholesale_of_radio_television_goods_electrical_household_appliances_other_than_records_tapes_cds_video_tapes_and_the_equipment_used_for_playing_them' },
  { label: 'Wholesale of Perfume and Cosmetics', value: 'wholesale_of_perfume_and_cosmetics' },
  { label: 'Wholesale of Office Furniture', value: 'wholesale_of_office_furniture' },
  { label: 'Retail Sale of Fruit and Vegetables in Specialised Stores', value: 'retail_sale_of_fruit_and_vegetables_in_specialised_stores' },
  { label: 'Retail Sale of Meat and Meat Products in Specialised Stores', value: 'retail_sale_of_meat_and_meat_products_in_specialised_stores' },
  { label: 'Retail Sale of Fish Crustaceans and Molluscs in Specialised Stores', value: 'retail_sale_of_fish_crustaceans_and_molluscs_in_specialised_stores' },
  { label: 'Retail Sale of Bread Cakes Flour Confectionery and Sugar Confectionery in Specialised Stores', value: 'retail_sale_of_bread_cakes_flour_confectionery_and_sugar_confectionery_in_specialised_stores' },
  { label: 'Retail Sale of Beverages in Specialised Stores', value: 'retail_sale_of_beverages_in_specialised_stores' },
  { label: 'Other Retail Sale of Food in Specialised Stores', value: 'other_retail_sale_of_food_in_specialised_stores' },
  { label: 'Retail Sale of Computers Peripheral Units and Software in Specialised Stores', value: 'retail_sale_of_computers_peripheral_units_and_software_in_specialised_stores' },
  { label: 'Retail Sale of Mobile Telephones', value: 'retail_sale_of_mobile_telephones' },
  { label: 'Retail Sale of Telecommunications Equipment Other than Mobile Telephones', value: 'retail_sale_of_telecommunications_equipment_other_than_mobile_telephones' },
  { label: 'Retail Sale of Audio and Video Equipment in Specialised Stores', value: 'retail_sale_of_audio_and_video_equipment_in_specialised_stores' },
  { label: 'Retail Sale of Textiles in Specialised Stores', value: 'retail_sale_of_textiles_in_specialised_stores' },
  { label: 'Retail Sale of Carpets Rugs Wall and Floor Coverings in Specialised Stores', value: 'retail_sale_of_carpets_rugs_wall_and_floor_coverings_in_specialised_stores' },
  { label: 'Retail Sale of Electrical Household Appliances in Specialised Stores', value: 'retail_sale_of_electrical_household_appliances_in_specialised_stores' },
  { label: 'Retail Sale of Musical Instruments and Scores', value: 'retail_sale_of_musical_instruments_and_scores' },
  { label: 'Retail of Furniture Lighting and Similar Not Musical Instruments or Scores in Specialised Store', value: 'retail_of_furniture_lighting_and_similar_not_musical_instruments_or_scores_in_specialised_store' },
  { label: 'Retail Sale of Books in Specialised Stores', value: 'retail_sale_of_books_in_specialised_stores' },
  { label: 'Retail Sale of Music and Video Recordings in Specialised Stores', value: 'retail_sale_of_music_and_video_recordings_in_specialised_stores' },
  { label: 'Retail Sale of Sports Goods Fishing Gear Camping Goods Boats and Bicycles', value: 'retail_sale_of_sports_goods_fishing_gear_camping_goods_boats_and_bicycles' },
  { label: 'Retail Sale of Games and Toys in Specialised Stores', value: 'retail_sale_of_games_and_toys_in_specialised_stores' },
  { label: 'Retail Sale of Clothing in Specialised Stores', value: 'retail_sale_of_clothing_in_specialised_stores' },
  { label: 'Retail Sale of Footwear in Specialised Stores', value: 'retail_sale_of_footwear_in_specialised_stores' },
  { label: 'Retail Sale of Cosmetic and Toilet Articles in Specialised Stores', value: 'retail_sale_of_cosmetic_and_toilet_articles_in_specialised_stores' },
  { label: 'Retail Sale of Flowers Plants Seeds Fertilizers Pet Animals and Pet Food in Specialised Stores', value: 'retail_sale_of_flowers_plants_seeds_fertilizers_pet_animals_and_pet_food_in_specialised_stores' },
  { label: 'Retail Sale by Opticians', value: 'retail_sale_by_opticians' },
  { label: 'Couriers Services', value: 'couriers_services' },
  { label: 'Taxi Operation', value: 'taxi_operation' },
  { label: 'Other Passenger Land Transport', value: 'other_passenger_land_transport' },
  { label: 'Logistic and Shipping Companies', value: 'logistic_and_shipping_companies' },
  { label: 'Other Transportation Support Activities', value: 'other_transportation_support_activities' },
  { label: 'Licensed Carriers', value: 'licensed_carriers' },
  { label: 'Hotels and Similar Accommodation B&B', value: 'hotels_and_similar_accommodation_b_b' },
  { label: 'Holiday Centres and Villages', value: 'holiday_centres_and_villages' },
  { label: 'Licensed Restaurants', value: 'licenced_restaurants' },
  { label: 'Take Away Food Shops and Mobile Food Stands', value: 'take_away_food_shops_and_mobile_food_stands' },
  { label: 'Hostels', value: 'hostels' },
  { label: 'Licensed Bar', value: 'licensed_bar' },
  { label: 'Other Food Services Such as Catering Agency', value: 'other_food_services_such_as_catering_agency' },
  { label: 'Fitness', value: 'fitness' },
  { label: 'Tour Guide Companies', value: 'tourguide_companies' },
  { label: 'Cinema', value: 'cinema' },
  { label: 'Theatres', value: 'theatres' },
  { label: 'Advertising Services', value: 'advertising_services' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Advertising Agencies', value: 'advertising_agencies' },
  { label: 'Media Representation Services', value: 'media_representation_services' },
  { label: 'Construction Businesses', value: 'construction_businesses' },
  { label: 'Building Maintenance and Repairs', value: 'building_maintenance_and_repairs' },
  { label: 'Hydraulics', value: 'hydraulics' },
  { label: 'Software Companies', value: 'software_companies' },
  { label: 'Business and Domestic Software Development', value: 'business_and_domestic_software_development' },
  { label: 'Programming', value: 'programming' },
  { label: 'Electricity Companies', value: 'electricity_companies' },
  { label: 'Gas Companies', value: 'gas_companies' },
  { label: 'Water Suppliers', value: 'water_suppliers' },
];


const whereIsClientIncorporatedOptions = [
  { label: 'Local', value: 'local' },
  { label: 'EU', value: 'eu' },
  { label: 'Non-EU', value: 'non_eu' },
];

// Options for incorporation country
const incorporationCountryOptions = [
  { label: 'Afghanistan', value: 'AFG' },
  { label: 'Åland Islands', value: 'ALA' },
  { label: 'Albania', value: 'ALB' },
  { label: 'Algeria', value: 'DZA' },
  { label: 'American Samoa', value: 'ASM' },
  { label: 'Andorra', value: 'AND' },
  { label: 'Angola', value: 'AGO' },
  { label: 'Anguilla', value: 'AIA' },
  { label: 'Antarctica', value: 'ATA' },
  { label: 'Antigua and Barbuda', value: 'ATG' },
  { label: 'Argentina', value: 'ARG' },
  { label: 'Armenia', value: 'ARM' },
  { label: 'Aruba', value: 'ABW' },
  { label: 'Australia', value: 'AUS' },
  { label: 'Austria', value: 'AUT' },
  { label: 'Azerbaijan', value: 'AZE' },
  { label: 'Bahamas', value: 'BHS' },
  { label: 'Bahrain', value: 'BHR' },
  { label: 'Bangladesh', value: 'BGD' },
  { label: 'Barbados', value: 'BRB' },
  { label: 'Belarus', value: 'BLR' },
  { label: 'Belgium', value: 'BEL' },
  { label: 'Belize', value: 'BLZ' },
  { label: 'Benin', value: 'BEN' },
  { label: 'Bermuda', value: 'BMU' },
  { label: 'Bhutan', value: 'BTN' },
  { label: 'Bolivia', value: 'BOL' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BES' },
  { label: 'Bosnia and Herzegovina', value: 'BIH' },
  { label: 'Botswana', value: 'BWA' },
  { label: 'Bouvet Island', value: 'BVT' },
  { label: 'Brazil', value: 'BRA' },
  { label: 'British Indian Ocean Territory', value: 'IOT' },
  { label: 'Brunei Darussalam', value: 'BRN' },
  { label: 'Bulgaria', value: 'BGR' },
  { label: 'Burkina Faso', value: 'BFA' },
  { label: 'Burundi', value: 'BDI' },
  { label: 'Cabo Verde', value: 'CPV' },
  { label: 'Cambodia', value: 'KHM' },
  { label: 'Cameroon', value: 'CMR' },
  { label: 'Canada', value: 'CAN' },
  { label: 'Cayman Islands', value: 'CYM' },
  { label: 'Central African Republic', value: 'CAF' },
  { label: 'Chad', value: 'TCD' },
  { label: 'Chile', value: 'CHL' },
  { label: 'China', value: 'CHN' },
  { label: 'Christmas Island', value: 'CXR' },
  { label: 'Cocos (Keeling) Islands', value: 'CCK' },
  { label: 'Colombia', value: 'COL' },
  { label: 'Comoros', value: 'COM' },
  { label: 'Congo', value: 'COG' },
  { label: 'Congo (Democratic Republic of the)', value: 'COD' },
  { label: 'Cook Islands', value: 'COK' },
  { label: 'Costa Rica', value: 'CRI' },
  { label: 'Côte d\'Ivoire', value: 'CIV' },
  { label: 'Croatia', value: 'HRV' },
  { label: 'Cuba', value: 'CUB' },
  { label: 'Curaçao', value: 'CUW' },
  { label: 'Cyprus', value: 'CYP' },
  { label: 'Czech Republic', value: 'CZE' },
  { label: 'Denmark', value: 'DNK' },
  { label: 'Djibouti', value: 'DJI' },
  { label: 'Dominica', value: 'DMA' },
  { label: 'Dominican Republic', value: 'DOM' },
  { label: 'Ecuador', value: 'ECU' },
  { label: 'Egypt', value: 'EGY' },
  { label: 'El Salvador', value: 'SLV' },
  { label: 'Equatorial Guinea', value: 'GNQ' },
  { label: 'Eritrea', value: 'ERI' },
  { label: 'Estonia', value: 'EST' },
  { label: 'Eswatini', value: 'SWZ' },
  { label: 'Ethiopia', value: 'ETH' },
  { label: 'Falkland Islands (Malvinas)', value: 'FLK' },
  { label: 'Faroe Islands', value: 'FRO' },
  { label: 'Fiji', value: 'FJI' },
  { label: 'Finland', value: 'FIN' },
  { label: 'France', value: 'FRA' },
  { label: 'French Guiana', value: 'GUF' },
  { label: 'French Polynesia', value: 'PYF' },
  { label: 'French Southern Territories', value: 'ATF' },
  { label: 'Gabon', value: 'GAB' },
  { label: 'Gambia', value: 'GMB' },
  { label: 'Georgia', value: 'GEO' },
  { label: 'Germany', value: 'DEU' },
  { label: 'Ghana', value: 'GHA' },
  { label: 'Gibraltar', value: 'GIB' },
  { label: 'Greece', value: 'GRC' },
  { label: 'Greenland', value: 'GRL' },
  { label: 'Grenada', value: 'GRD' },
  { label: 'Guadeloupe', value: 'GLP' },
  { label: 'Guam', value: 'GUM' },
  { label: 'Guatemala', value: 'GTM' },
  { label: 'Guernsey', value: 'GGY' },
  { label: 'Guinea', value: 'GIN' },
  { label: 'Guinea-Bissau', value: 'GNB' },
  { label: 'Guyana', value: 'GUY' },
  { label: 'Haiti', value: 'HTI' },
  { label: 'Heard Island and McDonald Islands', value: 'HMD' },
  { label: 'Holy See', value: 'VAT' },
  { label: 'Honduras', value: 'HND' },
  { label: 'Hong Kong', value: 'HKG' },
  { label: 'Hungary', value: 'HUN' },
  { label: 'Iceland', value: 'ISL' },
  { label: 'India', value: 'IND' },
  { label: 'Indonesia', value: 'IDN' },
  { label: 'Iran', value: 'IRN' },
  { label: 'Iraq', value: 'IRQ' },
  { label: 'Ireland', value: 'IRL' },
  { label: 'Isle of Man', value: 'IMN' },
  { label: 'Israel', value: 'ISR' },
  { label: 'Italy', value: 'ITA' },
  { label: 'Jamaica', value: 'JAM' },
  { label: 'Japan', value: 'JPN' },
  { label: 'Jersey', value: 'JEY' },
  { label: 'Jordan', value: 'JOR' },
  { label: 'Kazakhstan', value: 'KAZ' },
  { label: 'Kenya', value: 'KEN' },
  { label: 'Kiribati', value: 'KIR' },
  { label: 'Korea (Democratic People\'s Republic of)', value: 'PRK' },
  { label: 'Korea (Republic of)', value: 'KOR' },
  { label: 'Kuwait', value: 'KWT' },
  { label: 'Kyrgyzstan', value: 'KGZ' },
  { label: 'Lao People\'s Democratic Republic', value: 'LAO' },
  { label: 'Latvia', value: 'LVA' },
  { label: 'Lebanon', value: 'LBN' },
  { label: 'Lesotho', value: 'LSO' },
  { label: 'Liberia', value: 'LBR' },
  { label: 'Libya', value: 'LBY' },
  { label: 'Liechtenstein', value: 'LIE' },
  { label: 'Lithuania', value: 'LTU' },
  { label: 'Luxembourg', value: 'LUX' },
  { label: 'Macao', value: 'MAC' },
  { label: 'Madagascar', value: 'MDG' },
  { label: 'Malawi', value: 'MWI' },
  { label: 'Malaysia', value: 'MYS' },
  { label: 'Maldives', value: 'MDV' },
  { label: 'Mali', value: 'MLI' },
  { label: 'Malta', value: 'MLT' },
  { label: 'Marshall Islands', value: 'MHL' },
  { label: 'Martinique', value: 'MTQ' },
  { label: 'Mauritania', value: 'MRT' },
  { label: 'Mauritius', value: 'MUS' },
  { label: 'Mayotte', value: 'MYT' },
  { label: 'Mexico', value: 'MEX' },
  { label: 'Micronesia (Federated States of)', value: 'FSM' },
  { label: 'Moldova (Republic of)', value: 'MDA' },
  { label: 'Monaco', value: 'MCO' },
  { label: 'Mongolia', value: 'MNG' },
  { label: 'Montenegro', value: 'MNE' },
  { label: 'Montserrat', value: 'MSR' },
  { label: 'Morocco', value: 'MAR' },
  { label: 'Mozambique', value: 'MOZ' },
  { label: 'Myanmar', value: 'MMR' },
  { label: 'Namibia', value: 'NAM' },
  { label: 'Nauru', value: 'NRU' },
  { label: 'Nepal', value: 'NPL' },
  { label: 'Netherlands', value: 'NLD' },
  { label: 'New Caledonia', value: 'NCL' },
  { label: 'New Zealand', value: 'NZL' },
  { label: 'Nicaragua', value: 'NIC' },
  { label: 'Niger', value: 'NER' },
  { label: 'Nigeria', value: 'NGA' },
  { label: 'Niue', value: 'NIU' },
  { label: 'Norfolk Island', value: 'NFK' },
  { label: 'North Macedonia', value: 'MKD' },
  { label: 'Northern Mariana Islands', value: 'MNP' },
  { label: 'Norway', value: 'NOR' },
  { label: 'Oman', value: 'OMN' },
  { label: 'Pakistan', value: 'PAK' },
  { label: 'Palau', value: 'PLW' },
  { label: 'Palestine, State of', value: 'PSE' },
  { label: 'Panama', value: 'PAN' },
  { label: 'Papua New Guinea', value: 'PNG' },
  { label: 'Paraguay', value: 'PRY' },
  { label: 'Peru', value: 'PER' },
  { label: 'Philippines', value: 'PHL' },
  { label: 'Pitcairn', value: 'PCN' },
  { label: 'Poland', value: 'POL' },
  { label: 'Portugal', value: 'PRT' },
  { label: 'Puerto Rico', value: 'PRI' },
  { label: 'Qatar', value: 'QAT' },
  { label: 'Réunion', value: 'REU' },
  { label: 'Romania', value: 'ROU' },
  { label: 'Russian Federation', value: 'RUS' },
  { label: 'Rwanda', value: 'RWA' },
  { label: 'Saint Barthélemy', value: 'BLM' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SHN' },
  { label: 'Saint Kitts and Nevis', value: 'KNA' },
  { label: 'Saint Lucia', value: 'LCA' },
  { label: 'Saint Martin (French part)', value: 'MAF' },
  { label: 'Saint Pierre and Miquelon', value: 'SPM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VCT' },
  { label: 'Samoa', value: 'WSM' },
  { label: 'San Marino', value: 'SMR' },
  { label: 'Sao Tome and Principe', value: 'STP' },
  { label: 'Saudi Arabia', value: 'SAU' },
  { label: 'Senegal', value: 'SEN' },
  { label: 'Serbia', value: 'SRB' },
  { label: 'Seychelles', value: 'SYC' },
  { label: 'Sierra Leone', value: 'SLE' },
  { label: 'Singapore', value: 'SGP' },
  { label: 'Sint Maarten (Dutch part)', value: 'SXM' },
  { label: 'Slovakia', value: 'SVK' },
  { label: 'Slovenia', value: 'SVN' },
  { label: 'Solomon Islands', value: 'SLB' },
  { label: 'Somalia', value: 'SOM' },
  { label: 'South Africa', value: 'ZAF' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'SGS' },
  { label: 'South Sudan', value: 'SSD' },
  { label: 'Spain', value: 'ESP' },
  { label: 'Sri Lanka', value: 'LKA' },
  { label: 'Sudan', value: 'SDN' },
  { label: 'Suriname', value: 'SUR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJM' },
  { label: 'Sweden', value: 'SWE' },
  { label: 'Switzerland', value: 'CHE' },
  { label: 'Syrian Arab Republic', value: 'SYR' },
  { label: 'Taiwan', value: 'TWN' },
  { label: 'Tajikistan', value: 'TJK' },
  { label: 'Tanzania, United Republic of', value: 'TZA' },
  { label: 'Thailand', value: 'THA' },
  { label: 'Timor-Leste', value: 'TLS' },
  { label: 'Togo', value: 'TGO' },
  { label: 'Tokelau', value: 'TKL' },
  { label: 'Tonga', value: 'TON' },
  { label: 'Trinidad and Tobago', value: 'TTO' },
  { label: 'Tunisia', value: 'TUN' },
  { label: 'Turkey', value: 'TUR' },
  { label: 'Turkmenistan', value: 'TKM' },
  { label: 'Turks and Caicos Islands', value: 'TCA' },
  { label: 'Tuvalu', value: 'TUV' },
  { label: 'Uganda', value: 'UGA' },
  { label: 'Ukraine', value: 'UKR' },
  { label: 'United Arab Emirates', value: 'ARE' },
  { label: 'United Kingdom', value: 'GBR' },
  { label: 'United States of America', value: 'USA' },
  { label: 'United States Minor Outlying Islands', value: 'UMI' },
  { label: 'Uruguay', value: 'URY' },
  { label: 'Uzbekistan', value: 'UZB' },
  { label: 'Vanuatu', value: 'VUT' },
  { label: 'Venezuela', value: 'VEN' },
  { label: 'Viet Nam', value: 'VNM' },
  { label: 'Virgin Islands (British)', value: 'VGB' },
  { label: 'Virgin Islands (U.S.)', value: 'VIR' },
  { label: 'Wallis and Futuna', value: 'WLF' },
  { label: 'Western Sahara', value: 'ESH' },
  { label: 'Yemen', value: 'YEM' },
  { label: 'Zambia', value: 'ZMB' },
  { label: 'Zimbabwe', value: 'ZWE' },
];


const annualVolumeOptions = [
  { label: 'Less than 1M Euros', value: 'less_than_1M_euros' },
  { label: '1M to 2M Euros', value: '1M_to_2M_euros' },
  { label: '2M to 15M Euros', value: '2M_to_15M_euros' },
  { label: 'More than 15M Euros', value: 'more_than_15M_euros' },
];



// Render the modal
return (
  <Dialog
    open={isOpen}
    onClose={onClose}
    fullWidth
    maxWidth="lg"
    fullScreen
  >
    <Box sx={{ bgcolor: '#2196f3', color: 'white', textAlign: 'center', position: 'relative' }}>
    <IconButton
  edge="start"
  color="inherit"
  onClick={onClose}
  aria-label="close"
  sx={{ position: 'absolute', left: 8, top: 8 }}
>
  <ArrowBackIcon sx={{ fontSize: 40 }} />
</IconButton>
      <DialogTitle> Business Assessment</DialogTitle>
    </Box>

    <DialogContent>
      <Grid container spacing={2} style={{ marginTop: '20px', padding: '20px' }}>
        {/* General Information Section */}
        {/* General Information Section */}
        <Grid item xs={12}>
          <Typography variant="h6">General Information</Typography>
        </Grid>

        {/* How did the client get to know about Paynovate? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>How did the client get to know about Paynovate?</InputLabel>
            <Select
              value={generalInfo.how_client_get_to_know_paynovate}
              onChange={(e) => setGeneralInfo({ ...generalInfo, how_client_get_to_know_paynovate: e.target.value })}
              label="How did the client get to know about Paynovate?"
            >
              {howClientGetToKnowOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* How was the client met? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>How was the client met?</InputLabel>
            <Select
              value={generalInfo.how_was_the_client_met}
              onChange={(e) => setGeneralInfo({ ...generalInfo, how_was_the_client_met: e.target.value })}
              label="How was the client met?"
            >
              {howWasClientMetOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* What is the client sector category according to our merchant acceptance policy? */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={clientSectorCategoryOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="What is the client sector category according to our merchant acceptance policy?"
                variant="outlined"
              />
            )}
            value={
              clientSectorCategoryOptions.find(option => option.value === generalInfo.what_is_the_client_sector_category) || null
            }
            onChange={(event, newValue) => {
              setGeneralInfo({ ...generalInfo, what_is_the_client_sector_category: newValue ? newValue.value : '' });
            }}
          />
        </Grid>

        {/* Is there a clear view of the client's business activity? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Is there a clear view of the client's business activity?</InputLabel>
            <Select
              value={generalInfo.is_there_a_clear_view_of_the_client_business_activity}
              onChange={(e) => setGeneralInfo({ ...generalInfo, is_there_a_clear_view_of_the_client_business_activity: e.target.value })}
              label="Is there a clear view of the client's business activity?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Does this business activity make sense with the purpose of the account requested? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Does this business activity make sense with the purpose of the account requested?</InputLabel>
            <Select
              value={generalInfo.does_business_activity_make_sense_for_the_account}
              onChange={(e) => setGeneralInfo({ ...generalInfo, does_business_activity_make_sense_for_the_account: e.target.value })}
              label="Does this business activity make sense with the purpose of the account requested?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Where is the client incorporated? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Where is the client incorporated?</InputLabel>
            <Select
              value={generalInfo.where_is_the_client_incorporated}
              onChange={(e) => setGeneralInfo({ ...generalInfo, where_is_the_client_incorporated: e.target.value })}
              label="Where is the client incorporated?"
            >
              {whereIsClientIncorporatedOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Incorporation country name */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={incorporationCountryOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Incorporation country name"
                variant="outlined"
              />
            )}
            value={
              incorporationCountryOptions.find(option => option.value === generalInfo.incorporation_country_name) || null
            }
            onChange={(event, newValue) => {
              setGeneralInfo({ ...generalInfo, incorporation_country_name: newValue ? newValue.value : '' });
            }}
          />
        </Grid>

        {/* Sanctions */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sanctions</InputLabel>
            <Select
              value={generalInfo.sanctions}
              onChange={(e) => setGeneralInfo({ ...generalInfo, sanctions: e.target.value })}
              label="Sanctions"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Source of Financing Section */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h6">Source of Financing</Typography>
        </Grid>

        {/* Adverse media */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Adverse media</InputLabel>
            <Select
              value={sourceOfFinancing.adverse_media}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, adverse_media: e.target.value })}
              label="Adverse media"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Has the client provided management accounts or audited financial statements? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Has the client provided management accounts or audited financial statements?</InputLabel>
            <Select
              value={sourceOfFinancing.has_client_provided_audited_statements}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, has_client_provided_audited_statements: e.target.value })}
              label="Has the client provided management accounts or audited financial statements?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Does the stated SoW/SoF make sense with what you know about the client? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Does the stated SoW/SoF make sense with what you know about the client?</InputLabel>
            <Select
              value={sourceOfFinancing.does_sow_sof_make_sense}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, does_sow_sof_make_sense: e.target.value })}
              label="Does the stated SoW/SoF make sense with what you know about the client?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Is the SoW/SoF clearly identifiable? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Is the SoW/SoF clearly identifiable?</InputLabel>
            <Select
              value={sourceOfFinancing.is_sow_sof_identifiable}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, is_sow_sof_identifiable: e.target.value })}
              label="Is the SoW/SoF clearly identifiable?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Are the funds coming from a recognised reputable financial/credit institution? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Are the funds coming from a recognised reputable financial/credit institution?</InputLabel>
            <Select
              value={sourceOfFinancing.funds_from_reputable_institution}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_reputable_institution: e.target.value })}
              label="Are the funds coming from a recognised reputable financial/credit institution?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Are the funds coming from personal accounts? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Are the funds coming from personal accounts?</InputLabel>
            <Select
              value={sourceOfFinancing.funds_from_personal_accounts}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_personal_accounts: e.target.value })}
              label="Are the funds coming from personal accounts?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Are any of the funds coming from unrelated third parties? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Are any of the funds coming from unrelated third parties?</InputLabel>
            <Select
              value={sourceOfFinancing.funds_from_third_parties}
              onChange={(e) => setSourceOfFinancing({ ...sourceOfFinancing, funds_from_third_parties: e.target.value })}
              label="Are any of the funds coming from unrelated third parties?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Verification Section */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h6">Verification</Typography>
        </Grid>

        {/* Has the client provided acceptable proof of identification and proof of address for all the involved parties? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Has the client provided acceptable proof of identification and proof of address?</InputLabel>
            <Select
              value={verification.has_client_provided_proof_of_identification}
              onChange={(e) => setVerification({ ...verification, has_client_provided_proof_of_identification: e.target.value })}
              label="Has the client provided acceptable proof of identification and proof of address?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Have you been able to confirm the authenticity/professional status of the certifier? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Have you confirmed the authenticity/professional status of the certifier?</InputLabel>
            <Select
              value={verification.have_you_confirmed_authenticity_of_certifier}
              onChange={(e) => setVerification({ ...verification, have_you_confirmed_authenticity_of_certifier: e.target.value })}
              label="Have you confirmed the authenticity/professional status of the certifier?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Was the client hesitant to provide the requested documentation? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Was the client hesitant to provide the requested documentation?</InputLabel>
            <Select
              value={verification.was_client_hesitant_in_providing_documents}
              onChange={(e) => setVerification({ ...verification, was_client_hesitant_in_providing_documents: e.target.value })}
              label="Was the client hesitant to provide the requested documentation?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Has the sanctions screening tool provided any related positive hit? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sanctions screening provided positive hit?</InputLabel>
            <Select
              value={verification.sanctions_screening_provided_positive_hit}
              onChange={(e) => setVerification({ ...verification, sanctions_screening_provided_positive_hit: e.target.value })}
              label="Sanctions screening provided positive hit?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* UBO Section */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h6">UBO (Ultimate Beneficial Owner)</Typography>
        </Grid>

        {/* PEP */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>PEP</InputLabel>
            <Select
              value={ubo.pep}
              onChange={(e) => setUbo({ ...ubo, pep: e.target.value })}
              label="PEP"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Sanctions */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sanctions</InputLabel>
            <Select
              value={ubo.sanctions}
              onChange={(e) => setUbo({ ...ubo, sanctions: e.target.value })}
              label="Sanctions"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Adverse media */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Adverse media</InputLabel>
            <Select
              value={ubo.adverse_media}
              onChange={(e) => setUbo({ ...ubo, adverse_media: e.target.value })}
              label="Adverse media"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Residing in a sanctioned country */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Residing in a sanctioned country</InputLabel>
            <Select
              value={ubo.residing_in_sanctioned_country}
              onChange={(e) => setUbo({ ...ubo, residing_in_sanctioned_country: e.target.value })}
              label="Residing in a sanctioned country"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Residing in a high risk country */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Residing in a high risk country</InputLabel>
            <Select
              value={ubo.residing_in_high_risk_country}
              onChange={(e) => setUbo({ ...ubo, residing_in_high_risk_country: e.target.value })}
              label="Residing in a high risk country"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Directors Section */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h6">Directors</Typography>
        </Grid>

        {/* PEP */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>PEP</InputLabel>
            <Select
              value={directors.pep}
              onChange={(e) => setDirectors({ ...directors, pep: e.target.value })}
              label="PEP"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Sanctions */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sanctions</InputLabel>
            <Select
              value={directors.sanctions}
              onChange={(e) => setDirectors({ ...directors, sanctions: e.target.value })}
              label="Sanctions"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Adverse media */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Adverse media</InputLabel>
            <Select
              value={directors.adverse_media}
              onChange={(e) => setDirectors({ ...directors, adverse_media: e.target.value })}
              label="Adverse media"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Residing in a sanctioned country */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Residing in a sanctioned country</InputLabel>
            <Select
              value={directors.residing_in_sanctioned_country}
              onChange={(e) => setDirectors({ ...directors, residing_in_sanctioned_country: e.target.value })}
              label="Residing in a sanctioned country"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Residing in a high risk country */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Residing in a high risk country</InputLabel>
            <Select
              value={directors.residing_in_high_risk_country}
              onChange={(e) => setDirectors({ ...directors, residing_in_high_risk_country: e.target.value })}
              label="Residing in a high risk country"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Transactions Section */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h6">Transactions</Typography>
        </Grid>

        {/* Did the client provide us with the expected total monthly inwards volume? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Provided expected monthly inwards volume?</InputLabel>
            <Select
              value={transactions.did_client_provide_expected_monthly_inwards_volume}
              onChange={(e) => setTransactions({ ...transactions, did_client_provide_expected_monthly_inwards_volume: e.target.value })}
              label="Provided expected monthly inwards volume?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Expected total incoming volume in EUR per annum */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Expected annual incoming volume</InputLabel>
            <Select
              value={transactions.expected_annual_incoming_volume}
              onChange={(e) => setTransactions({ ...transactions, expected_annual_incoming_volume: e.target.value })}
              label="Expected annual incoming volume"
            >
              {annualVolumeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Did the client provide us with the expected total monthly outwards volume? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Provided expected monthly outwards volume?</InputLabel>
            <Select
              value={transactions.did_client_provide_expected_monthly_outwards_volume}
              onChange={(e) => setTransactions({ ...transactions, did_client_provide_expected_monthly_outwards_volume: e.target.value })}
              label="Provided expected monthly outwards volume?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Expected total outgoing volume in EUR per annum */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Expected annual outgoing volume</InputLabel>
            <Select
              value={transactions.expected_annual_outgoing_volume}
              onChange={(e) => setTransactions({ ...transactions, expected_annual_outgoing_volume: e.target.value })}
              label="Expected annual outgoing volume"
            >
              {annualVolumeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Did the client provide us with the 5 counterparties they expect to transact inwards with the most? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Provided 5 counterparties inwards?</InputLabel>
            <Select
              value={transactions.did_client_provide_5_counterparties_inwards}
              onChange={(e) => setTransactions({ ...transactions, did_client_provide_5_counterparties_inwards: e.target.value })}
              label="Provided 5 counterparties inwards?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Did the client provide us with the 5 counterparties they expect to transact outwards with the most? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Provided 5 counterparties outwards?</InputLabel>
            <Select
              value={transactions.did_client_provide_5_counterparties_outwards}
              onChange={(e) => setTransactions({ ...transactions, did_client_provide_5_counterparties_outwards: e.target.value })}
              label="Provided 5 counterparties outwards?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Is the client involved in a cash dominant turnover business? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Involved in cash dominant turnover?</InputLabel>
            <Select
              value={transactions.is_client_involved_in_cash_dominant_turnover}
              onChange={(e) => setTransactions({ ...transactions, is_client_involved_in_cash_dominant_turnover: e.target.value })}
              label="Involved in cash dominant turnover?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Could the transactions be used for money laundering? */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Transactions at risk of money laundering?</InputLabel>
            <Select
              value={transactions.is_transaction_risk_of_money_laundering}
              onChange={(e) => setTransactions({ ...transactions, is_transaction_risk_of_money_laundering: e.target.value })}
              label="Transactions at risk of money laundering?"
            >
              {yesNoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </DialogContent>
    <Box textAlign='center' m={2}>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Save and Continue
      </Button>
    </Box>
    <Modal_KycUpdate
        isOpen={isKycUpdateOpen}
        onClose={handleCloseBothModals} // Pass the function to close both modals
        assessmentData={assessmentData}
        business={business}
      />
  </Dialog>
);
};


export default Modal_kybAssessment;
