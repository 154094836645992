import React, { useState, useEffect } from 'react';
import { theme } from './ReactStyles';
import {  Table, TableBody, TableCell, TableHead, TableRow,  Select, MenuItem, FormControl, InputLabel,  ThemeProvider } from '@mui/material';

import TablePagination from '@mui/material/TablePagination';
import { get_financial_data } from './Util_API_calls';
import { CircularProgress } from '@mui/material';


function Page_Pl({ onDataChange, ...props }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [granularity, setAggregateBy] = useState('day');
  const [information, setInformation] = useState('profits_total');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [days, setDays] = useState([]);
  const [day, setDay] = useState(''); // Add this line to define 'day' state

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const years = []; // Populate this array with the years you want to include

  const [month, setMonth] = useState(months[currentMonth]);
  const [year, setYear] = useState(currentYear);


  const handleDayChange = (event) => {
    setDay(event.target.value);
    // Handle day change logic
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };


  const startYear = 2023; // Define the start year as 2023
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    // Update days based on the new month and the currently selected year
    const daysInMonth = getDaysInMonth(months.indexOf(newMonth) + 1, year);
    setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setYear(newYear);
    // Update days based on the new year and the currently selected month
    const daysInMonth = getDaysInMonth(months.indexOf(month) + 1, newYear);
    setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  };

  const handleAggregateChange = (event) => {
    const selectedValue = event.target.value;
    setAggregateBy(selectedValue);

    // If aggregate by month is selected, reset the month
    if (selectedValue === 'month') {
      setDay(null);
    }
  };

  useEffect(() => {
    setDay(currentDate.getDate());
    setDays(Array.from({ length: getDaysInMonth(currentMonth + 1, currentYear) }, (_, i) => i + 1));
  }, []);

  useEffect(() => {
    // Fetch financial data
    const fetchfinancial = async () => {
      setLoading(true);
      const today = new Date();
      const date_start = '2024-01-01';
      const date_end = today.toISOString().split('T')[0];
      const response = await get_financial_data('pl', date_start, date_end);
      if (response && response.status === 'success') {
        setFinancialData(response.data);
      }
      setLoading(false);
    };
    fetchfinancial();
  }, [day, month, year]);

  const getTitleFromKey = (key) => {
    const parts = key.split('_');
    return parts.slice(0, -3).join(' ');
  };

  const prepareTableRows = (financialData, selectedDate, page, rowsPerPage) => {
    const filteredData = granularity === 'day' && financialData[selectedDate]
      ? { [selectedDate]: financialData[selectedDate] }
      : financialData;
  
    return Object.entries(filteredData)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(([date, data]) => {
        const groupedData = Object.entries(data).reduce((acc, [key, value]) => {
          const category = getTitleFromKey(key);
          const type = key.split('_').slice(-3).join('_');
          if (!acc[category]) acc[category] = { title: category };
          acc[category][type] = value;
          return acc;
        }, {});
  
        return Object.entries(groupedData).map(([category, values]) => (
          <TableRow key={category}>
            <TableCell>{values.title.replace(/\b(\w)/g, match => match.toUpperCase())}</TableCell>
            <TableCell>{values["ul_fixed_component"]} €</TableCell>
            <TableCell>{values["ul_percentage_component"]} €</TableCell>
            <TableCell>{values["fdt_fixed_component"]} €</TableCell>
            <TableCell>{values["fdt_percentage_component"]} €</TableCell>
            <TableCell>{values["crh_fixed_component"]} €</TableCell>
            <TableCell>{values["crh_percentage_component"]} €</TableCell>
          </TableRow>
        ));
      });
  };
  


  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const formatNumber = (num) => String(num).padStart(2, '0');

  const selectedDateString = granularity === 'day'
    ? `${year}-${formatNumber(months.indexOf(month) + 1)}-${formatNumber(day)}`
    : `${year}-${formatNumber(months.indexOf(month) + 1)}`;

  const tableRows = prepareTableRows(financialData, selectedDateString, page, rowsPerPage);
  
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }}>
      <ThemeProvider theme={theme}>
        <FormControl variant="outlined" style={{ marginRight: '20px' }}>
          <InputLabel id="aggregate-by-label">Aggregate By</InputLabel>
          <Select style={{ width: '350px', display: 'none' }}
            labelId="aggregate-by-label"
            id="aggregate-by-select"
            value={granularity}
            onChange={handleAggregateChange}
            label="Aggregate By"          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="month">Month (last day)</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ marginRight: '20px' }}>
          <InputLabel id="year-label">Year</InputLabel>
          <Select style={{ width: '200px' }}
            labelId="year-label"
            id="year-select"
            value={year}
            onChange={handleYearChange}
            label="Year"
          >
            {years.map(y => (
              <MenuItem key={y} value={y}>{y}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ marginRight: '20px' }}>
          <InputLabel id="month-label">Month</InputLabel>
          <Select style={{ width: '200px' }}
            labelId="month-label"
            id="month-select"
            value={month}
            onChange={handleMonthChange}
            label="Month"
          >
            {months.map((m, index) => (
              <MenuItem key={index} value={m}>{m}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {granularity === 'day' && (
          <FormControl variant="outlined" style={{ marginRight: '20px' }}>
            <InputLabel id="day-label">Day</InputLabel>
            <Select
              style={{ width: '200px' }}
              labelId="day-label"
              id="day-select"
              value={day}
              onChange={handleDayChange}
              label="Day"
            >
              {days.map(d => (
                <MenuItem key={d} value={d}>{d}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Table style={{ marginTop: '20px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Unlimit fixed P/L</TableCell>
              <TableCell>Unlimit percentage P/L</TableCell>
              <TableCell>FDT fixed P/L</TableCell>
              <TableCell>FDT percentage P/L</TableCell>
              <TableCell>CRH fixed P/L</TableCell>
              <TableCell>CRH percentage P/L</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {tableRows}
          </TableBody>

        </Table>
        <TablePagination
          component="div"
          count={Object.keys(financialData).length} // Count should be the total number of dates
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={'Rows per page'}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </ThemeProvider>
    </div>
  );

}

export default Page_Pl;