import React, { useState, useRef } from 'react';
import { CircularProgress, Typography, Box, MenuItem, Select, FormControl, InputLabel, TextField, Button } from '@mui/material';
import { sendOTPDoubleAuth, verifyOTPDoubleAuth, disableService, enableService } from './Util_API_calls';

const MaintenancePage = () => {
  const [startDate, setStartDate] = useState(new Date().toISOString().substring(0, 10));
  const [startTime, setStartTime] = useState(new Date().toISOString().substring(11, 16));
  const [duration, setDuration] = useState('');
  const [timezone] = useState('UTC');
  const [serviceCode, setServiceCode] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otp2, setOtp2] = useState(Array(6).fill(''));
  const [isProcessingDisable, setIsProcessingDisable] = useState(false);
  const [isProcessingEnable, setIsProcessingEnable] = useState(false);
  const [errorMessageDisable, setErrorMessageDisable] = useState('');
  const [errorMessageEnable, setErrorMessageEnable] = useState('');
  const [successMessageDisable, setSuccessMessageDisable] = useState('');
  const [successMessageEnable, setSuccessMessageEnable] = useState('');
  const [otpSentDisable, setOtpSentDisable] = useState(false);
  const [otpSentEnable, setOtpSentEnable] = useState(false);
  const [otpVerifiedDisable, setOtpVerifiedDisable] = useState(false);
  const [otpVerifiedEnable, setOtpVerifiedEnable] = useState(false);
  const [action, setAction] = useState(null);
  const [lastDisableDetails, setLastDisableDetails] = useState(null);
  const [lastEnableDetails, setLastEnableDetails] = useState(null);
  

  const serviceOptions = [
    { code: 'all-systems', label: 'Tutti i sistemi' },
    { code: 'epay_phone_recharge', label: 'Ricariche telefoniche' },
    { code: 'epay_bollettini896', label: 'Epay Bollettini' },
    { code: 'epay_pagopa', label: 'Epay PagoPA' },
    { code: 'epay_bolloauto', label: 'Epay Bollo Auto' },
    { code: 'epay_mav', label: 'Epay MAV' },
    { code: 'epay_rav', label: 'Epay RAV' },
    { code: 'epay_vouchers_with_pin', label: 'Epay Vouchers con PIN' },
    { code: 'baas-all', label: 'Baas Tutti' },
    { code: 'baas-payments', label: 'Baas Pagamenti' },
    { code: 'baas-account-recharges', label: 'Baas Ricariche Conto' },
  ];

  const handleSendOTP = async (action) => {
    if (action === 'disable') {
      setIsProcessingDisable(true);
    } else {
      setIsProcessingEnable(true);
    }
    try {
      const response = await sendOTPDoubleAuth('admin_universal_double_auth', 'sms');
      if (action === 'disable') {
        setOtpSentDisable(true);
        setSuccessMessageDisable('OTP inviato con successo');
      } else {
        setOtpSentEnable(true);
        setSuccessMessageEnable('OTP inviato con successo');
      }
    } catch (error) {
      if (action === 'disable') {
        setErrorMessageDisable('Errore durante l\'invio dell\'OTP');
      } else {
        setErrorMessageEnable('Errore durante l\'invio dell\'OTP');
      }
    } finally {
      if (action === 'disable') {
        setIsProcessingDisable(false);
      } else {
        setIsProcessingEnable(false);
      }
    }
  };

  const handleVerifyOTP = async (action) => {
    if (action === 'disable') {
      setIsProcessingDisable(true);
    } else {
      setIsProcessingEnable(true);
    }
    try {
      const otpCode = action === 'disable' ? otp.join('') : otp2.join('');
      const response = await verifyOTPDoubleAuth(otpCode);
      if (response.status === 'success') {
        if (action === 'disable') {
          setOtpVerifiedDisable(true);
        } else {
          setOtpVerifiedEnable(true);
        }
      } else {
        if (action === 'disable') {
          setErrorMessageDisable('Errore durante la verifica dell\'OTP');
        } else {
          setErrorMessageEnable('Errore durante la verifica dell\'OTP');
        }
        resetState(action);
      }
    } catch (error) {
      if (action === 'disable') {
        setErrorMessageDisable('Errore durante la verifica dell\'OTP');
      } else {
        setErrorMessageEnable('Errore durante la verifica dell\'OTP');
      }
      resetState(action);
    } finally {
      if (action === 'disable') {
        setIsProcessingDisable(false);
      } else {
        setIsProcessingEnable(false);
      }
    }
  };

  const handleDisableService = async () => {
    setIsProcessingDisable(true);
    setErrorMessageDisable('');
    setSuccessMessageDisable('');
    const serviceData = {
      maintenance_start_date: startDate,
      maintenance_start_time: startTime,
      timezone: timezone,
      duration_in_minutes: duration,
      service_code: serviceCode,
    };
    try {
      const response = await disableService(serviceData);
      if (response.status === 'success') {
        setSuccessMessageDisable('Servizio disabilitato con successo');
        setLastDisableDetails({
          service: serviceOptions.find(option => option.code === serviceCode)?.label,
          start: new Date(response.systems[serviceCode]?.start_time).toLocaleString(),
          end: new Date(response.systems[serviceCode]?.end_time).toLocaleString(),
        });
        resetState('disable');
      } else {
        setErrorMessageDisable('Errore durante la disabilitazione del servizio');
      }
    } catch (error) {
      setErrorMessageDisable('Errore durante la disabilitazione del servizio');
    } finally {
      setIsProcessingDisable(false);
      setAction(null);
    }
  };

  const handleEnableService = async () => {
    setIsProcessingEnable(true);
    setErrorMessageEnable('');
    setSuccessMessageEnable('');
    const currentTime = new Date().toISOString();
    try {
      const response = await enableService(serviceCode);
      if (response.status === 'success') {
        setSuccessMessageEnable('Servizio abilitato con successo');
        setLastEnableDetails({
          service: serviceOptions.find(option => option.code === serviceCode)?.label,
          time: new Date(currentTime).toLocaleString(),
        });
        resetState('enable');
      } else {
        setErrorMessageEnable('Errore durante l\'abilitazione del servizio');
      }
    } catch (error) {
      setErrorMessageEnable('Errore durante l\'abilitazione del servizio');
    } finally {
      setIsProcessingEnable(false);
      setAction(null);
    }
  };

  const handleOtpChange = (index, value, action) => {
    if (!/^[0-9]$/.test(value)) return;
    if (action === 'disable') {
      setOtp(prevOtp => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      if (index < otp.length - 1) {
        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      }
    } else {
      setOtp2(prevOtp => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      if (index < otp2.length - 1) {
        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      }
    }
  };

  const resetState = (action) => {
    if (action === 'disable') {
      setOtp(Array(6).fill(''));
      setOtpSentDisable(false);
      setOtpVerifiedDisable(false);
      setServiceCode('');
      setStartDate(new Date().toISOString().substring(0, 10));
      setStartTime(new Date().toISOString().substring(11, 16));
      setDuration('');
    } else {
      setOtp2(Array(6).fill(''));
      setOtpSentEnable(false);
      setOtpVerifiedEnable(false);
      setServiceCode('');
    }
  };

  const inputsRef = useRef([]);

  return (
    <Box p={3} textAlign="center">
      <Typography variant="h4" gutterBottom style={{ color: '#339AD8' }}>Manutenzione</Typography>
      <Typography variant="subtitle1" gutterBottom style={{ color: '#339AD8' }}>Inserire i dati di manutenzione</Typography>
      <Box display="flex" justifyContent="space-around">
        <Box width="45%" p={2} border={1} borderRadius={8} borderColor="grey.400">
          <Button onClick={() => { resetState('disable'); setAction('disable'); }} variant="contained" style={{ backgroundColor: 'red', color: 'white', fontSize: '1.5rem' }}>
            Disabilita
          </Button>
          {action === 'disable' && (
            <>
              {!otpSentDisable ? (
                <Box mt={2}>
                  <Button onClick={() => handleSendOTP('disable')} variant="contained" color="primary" disabled={isProcessingDisable || otpSentDisable}>
                    {isProcessingDisable ? <CircularProgress size={24} /> : 'Invia OTP'}
                  </Button>
                </Box>
              ) : !otpVerifiedDisable ? (
                <>
                  <Box mt={2} display="flex" justifyContent="center">
                    {otp.map((digit, index) => (
                      <TextField
                        key={index}
                        variant="outlined"
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: 'center' }
                        }}
                        inputRef={el => inputsRef.current[index] = el}
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value, 'disable')}
                      />
                    ))}
                  </Box>
                  <Box mt={2}>
                    <Button onClick={() => handleVerifyOTP('disable')} variant="contained" color="secondary" disabled={isProcessingDisable} style={{ fontSize: '1.5rem', padding: '10px 20px' }}>
                      {isProcessingDisable ? <CircularProgress size={24} /> : 'Autentica'}
                    </Button>
                  </Box>
                  {errorMessageDisable && (
                    <Box color="error.main" mt={2}>
                      <Typography>{errorMessageDisable}</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Data Inizio"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: new Date().toISOString().substring(0, 10)
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Ora Inizio"
                      type="time"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Durata (in minuti)"
                      type="number"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Servizio</InputLabel>
                    <Select
                      value={serviceCode}
                      onChange={(e) => setServiceCode(e.target.value)}
                    >
                      {serviceOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Button onClick={handleDisableService} variant="contained" color="secondary" disabled={isProcessingDisable} style={{ fontSize: '1.5rem', padding: '10px 20px' }}>
                      {isProcessingDisable ? <CircularProgress size={24} /> : 'Disabilita'}
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>

        <Box width="45%" p={2} border={1} borderRadius={8} borderColor="grey.400">
          <Button onClick={() => { resetState('enable'); setAction('enable'); }} variant="contained" style={{ backgroundColor: 'red', color: 'white', fontSize: '1.5rem' }}>
            Abilita
          </Button>
          {action === 'enable' && (
            <>
              {!otpSentEnable ? (
                <Box mt={2}>
                  <Button onClick={() => handleSendOTP('enable')} variant="contained" color="primary" disabled={isProcessingEnable || otpSentEnable}>
                    {isProcessingEnable ? <CircularProgress size={24} /> : 'Invia OTP'}
                  </Button>
                </Box>
              ) : !otpVerifiedEnable ? (
                <>
                  <Box mt={2} display="flex" justifyContent="center">
                    {otp2.map((digit, index) => (
                      <TextField
                        key={index}
                        variant="outlined"
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: 'center' }
                        }}
                        inputRef={el => inputsRef.current[index] = el}
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value, 'enable')}
                      />
                    ))}
                  </Box>
                  <Box mt={2}>
                    <Button onClick={() => handleVerifyOTP('enable')} variant="contained" color="secondary" disabled={isProcessingEnable} style={{ fontSize: '1.5rem', padding: '10px 20px' }}>
                      {isProcessingEnable ? <CircularProgress size={24} /> : 'Autentica'}
                    </Button>
                  </Box>
                  {errorMessageEnable && (
                    <Box color="error.main" mt={2}>
                      <Typography>{errorMessageEnable}</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Servizio</InputLabel>
                    <Select
                      value={serviceCode}
                      onChange={(e) => setServiceCode(e.target.value)}
                    >
                      {serviceOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Button onClick={handleEnableService} variant="contained" color="secondary" disabled={isProcessingEnable} style={{ fontSize: '1.5rem', padding: '10px 20px' }}>
                      {isProcessingEnable ? <CircularProgress size={24} /> : 'Abilita'}
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      {lastDisableDetails && (
        <Box mt={2} style={{ color: 'black' }}>
          <Typography variant="h6" gutterBottom>Ultima operazione di disabilitazione</Typography>
          <Typography variant="body1">Servizi disabilitati con successo</Typography>
          <Typography variant="body1">Servizi: {lastDisableDetails.service}</Typography>
          <Typography variant="body1">From: {lastDisableDetails.start}</Typography>
          <Typography variant="body1">Until: {lastDisableDetails.end}</Typography>
        </Box>
      )}

      {lastEnableDetails && (
        <Box mt={2} style={{ color: 'black' }}>
          <Typography variant="h6" gutterBottom>Ultima operazione di abilitazione</Typography>
          <Typography variant="body1">Servizi abilitati con successo</Typography>
          <Typography variant="body1">Servizi: {lastEnableDetails.service}</Typography>
          <Typography variant="body1">Time: {lastEnableDetails.time}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default MaintenancePage;
